import React from 'react'
import { Link } from 'gatsby'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { PLANS } from '@constants/routes'

export default function CouponStrip() {
    return (
        <div className='relative isolate flex items-center gap-x-2 overflow-hidden bg-mandy px-2 py-2.5 sm:px-3.5 sm:before:flex-1'>
            <Link to={PLANS} className='flex flex-wrap items-center gap-x-4 gap-y-2'>
                <p className='text-center text-sm leading-6 text-white'>
                    <strong className='font-lmp-light'>⚡ Black Friday ⚡</strong>
                    <svg viewBox='0 0 2 2' className='mx-2 inline size-0.5 fill-current' aria-hidden='true'>
                        <circle cx={1} cy={1} r={1} />
                    </svg>
                    <span className='font-lmp-medium'>25%</span> de descuento en planes anuales, usa el cupón
                    <span className='mx-1 flex-none rounded bg-gray-900 px-2 py-0.5 font-lmp-regular text-xs text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900'>
                        BLACK24
                    </span>
                    hasta el 30 de Noviembre.
                </p>
            </Link>
            <div className='flex flex-1 justify-end'>
                <button type='button' className='-m-3 p-3 focus-visible:outline-offset-[-4px]'>
                    <span className='sr-only'>Dismiss</span>
                    <XMarkIcon className='size-5 text-white' aria-hidden='true' />
                </button>
            </div>
        </div>
    )
}
