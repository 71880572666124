//
// internal routes
export const HOME = '/'
export const PLANS = '/plans'
export const TERMS = '/terms'
export const BLOG = '/blog'
export const PODCAST = '/podcast'
export const ABOUT = '/about'
export const PRIVACY = '/privacy'
export const SIGN_UP = '/signup'
export const THANKS = '/thanks'
export const DEMO = '/demo'
export const CONTACT = '/contact'
export const RESOURCES = '/resources'

//
// external routes
export const TRIBU_HELP_CENTER = 'https://help.tribu.yoga'
export const TRIBU_APP = 'https://app.tribu.yoga'
export const TRIBU_YOUTUBE = 'https://www.youtube.com/@tribuyoga5509'
export const TRIBU_INSTAGRAM = 'https://www.instagram.com/tribu.yoga.app'
export const TRIBU_PINTEREST = 'https://www.pinterest.com/TribuYogaApp'
export const TRIBU_SPOTIFY = 'https://open.spotify.com/show/7cYmTKmRHCchk0ibyiPJgY'
export const TRIPPING_CATS = 'https://www.trippingcats.com'
