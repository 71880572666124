// import react
import React from 'react'

// import global styles
import '@assets/css/main.css'

// configure layout
import Layout from '@components/Layout'

// props provide same data to Layout as Page element will get
// including location, data, etc - you don't need to pass it
export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>
}

// smooth scroll behavior polyfill
export const onClientEntry = () => {
    //
    // Paddle
    window.onload = () => {
        const script = document.createElement('script')
        script.src = 'https://cdn.paddle.com/paddle/paddle.js'
        script.async = true
        document.body.appendChild(script)

        script.onload = () => {
            //
            // Check if sandbox ENV is set
            if (process.env.GATSBY_PADDLE_ENV === 'sandbox') {
                window.Paddle.Environment.set('sandbox')
            }
            //
            // Init Paddle, debug only for sandbox
            window.Paddle.Setup({
                vendor: parseInt(process.env.GATSBY_PADDLE_VENDOR_ID),
                debug: process.env.GATSBY_PADDLE_ENV === 'sandbox',
            })
        }
    }
}
